/********  FAD Location Index  ********/

.cities-background {
    background-color: #edf1ed;
    padding: 20px;
}

.cities-row {
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; */
    column-count: 1;
    margin-right: -10px;
    margin-left: -10px;
    margin-top: 25px;
}

.cities-col {
    width: 100%;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 1.5rem;
}

.cities-row .cities-block {
    margin-bottom: 0;
}

.cities-link-block {
    height: 100%;
    border-radius: 5px;
    border: 1px solid #EBEBEB;
    font-size: 14px;
    background: #fff;
    padding: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.cities-link-block a {
    display: inline-block;
    text-align: left;
    color: #3d3d3d;
    font-size: 20px;
}

.cities-link-block a img {
    width: 26px;
    margin-bottom: 25px;
    transition: all 0.5s;
    position: relative;
}

.cities-link-block a:hover img {
    margin-left: 10px;
}

.cities-link-block a span {
    display: block;
}

.cities-block {
    width: 100%;
    margin-bottom: 1.5rem;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #EBEBEB;
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    font-size: 14px;
}

.cities-block .cities-block-title {
    font-size: 1.15em;
    padding: 12px 15px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #EBEBEB;
    margin: 0;
}
.cities-block .cities-block-title a{
    color: var(--bs-body-color);
}
.cities-block .cities-block-body {
    padding: 5px 14px;
    background-color: #fff;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.cities-block .cities-block-body ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1em;
}

.cities-block .cities-block-body ul li {
    padding: 10px 0;
}

.cities-block .cities-block-body ul li a {
    color: #3d3d3d;
}

.cities-block .cities-block-body ul li a:hover {
    color: var(--bs-link-color);
}

#pollen-location-index .cities-block .cities-block-body ul {
    column-count: 2;
}

/* @media only screen and (min-width: 575px) {
    .cities-col {
        width: 50%;
        max-width: 50%;
    }
} */
@media only screen and (min-width: 576px) {
    .cities-row {
        column-count: 2;
    }
}
@media only screen and (min-width: 768px) {
    .cities-block .cities-block-title {
        font-size:20px;
    }
    .cities-background {
        padding: 40px;
    }
    /* .cities-col {
        width: 33.33%;
        max-width: 33.33%;
    } */
    .cities-row {
        column-count: 3;
    }
    #pollen-location-index .cities-row {
        column-count: 2;
    }    
}

@media only screen and (min-width: 900px) {
    #pollen-location-index .cities-block .cities-block-body ul {
        column-count: 3;
    }
}
@media only screen and (min-width: 1200px) {
    .cities-block {
        border-radius: 10px;
        font-size: 16px;
    }
    .cities-block .cities-block-title {
        padding: 19px 23px;
    }
    .cities-block .cities-block-body {
        padding: 10px 20px;
    }
    .cities-block .cities-block-body ul li {
        padding: 10px 0;
    }
    .cities-background {
        padding: 40px 5%;
    }
    .cities-link-block {
        border-radius: 10px;
    }
    .cities-col {
        width: 100%;
        max-width: 100%;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    }
    .cities-row {
        column-count: 4;
    }
    #pollen-location-index .cities-row {
        column-count: 2;
    }
}

#fad-location-index .cities-row{
    column-count: initial;
}

#fad-location-index .cities-row .cities-col:first-child:last-child .cities-block-body > ul{
    display: flex;
    flex-wrap: wrap;
}
#fad-location-index .cities-row .cities-col:first-child:last-child .cities-block-body > ul > li{
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}
@media only screen and (min-width: 576px) {
    #fad-location-index .cities-row .cities-col:first-child:last-child .cities-block-body > ul > li{
        padding-left: 15px;
        padding-right: 15px;
        width: 50%;
    }
}
@media only screen and (min-width: 768px) {
    #fad-location-index .cities-row .cities-col:first-child:last-child .cities-block-body > ul > li{
        padding-left: 15px;
        padding-right: 15px;
        width: 33.33%;
    }
}
@media only screen and (min-width: 900px) {
    #fad-location-index .cities-row .cities-col:first-child:last-child .cities-block-body > ul > li{
        padding-left: 15px;
        padding-right: 15px;
        width: 25%;
    }
}
@media only screen and (max-width: 767px) {
    #fad-location-index .cities-background h4{
        font-size: 22px;
    }
}

#pollen-location-index .cities-block .cities-block-title a {
    color: var(--bs-link-color);
}
#pollen-location-index .cities-block .cities-block-title a:hover {
    color: var(--bs-link-hover-color);
}

.clinic-index-page-link {
    opacity: 0.8;
    font-size: 12px;
    color: #8db58d !important;
}

/********  FAD Location Index  ********/